import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loadable from "react-loadable";

import "../../node_modules/font-awesome/scss/font-awesome.scss";
import RoutePrivate from "../App/components/ValidateSession/RoutePrivate";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import AuthState from "../App/context/auth/authState";
import Login from "../App/components/Auth/Login";
import tokenAuth from "./config/token";
const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}
// class App extends Component {
//   render() {
//     const menu = routes.map((route, index) => {
//       return route.component ? (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           name={route.name}
//           render={(props) => <route.component {...props} />}
//         />
//       ) : null;
//     });

//     return (
//       <Aux>
//         <ScrollToTop>
//           <Suspense fallback={<Loader />}>
//             <Switch>
//               {menu}
//               <Route path="/" component={AdminLayout} />
//             </Switch>
//           </Suspense>
//         </ScrollToTop>
//       </Aux>
//     );
//   }
// }

// export default App;
const menu = routes.map((route, index) => {
  return route.component ? (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      name={route.name}
      render={(props) => <route.component {...props} />}
    />
  ) : null;
});

function App() {
  return (
    <Aux>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <AuthState>
            <Switch>
              {menu}
              {/* <Route path="/" component={AdminLayout} /> */}
              <Route path="/Auth/Login" component={Login} />
              <RoutePrivate path="/" component={AdminLayout} />
            </Switch>
          </AuthState>
        </Suspense>
      </ScrollToTop>
    </Aux>
  );
}

export default App;
