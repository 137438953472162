export const FORMULARIO_PROYECTO = "NUEVO_PROYECTO";

export const VALIDAR_SESION = "VALIDAR_SESION";

// export const FORMULARIO_PROYECTO = 'FORMULARIO_PROYECTO'
export const OBTENER_PROYECTOS = "OBTENER_PROYECTOS";
export const AGREGAR_PROYECTO = "AGREGAR_PROYECTO";
export const VALIDAR_FORMULARIO = "VALIDAR_FORMULARIO";
export const PROYECTO_ACTUAL = "PROYECTO_ACTUAL";
export const ELIMINAR_PROYECTO = "ELIMINAR_PROYECTO";
export const PROYECTO_ERROR = "PROYECTO_ERROR";

export const TAREAS_PROYECTO = "TAREAS_PROYECTO";
export const AGREGAR_TAREA = "AGREGAR_TAREA";
export const VALIDAR_TAREA = "VALIDAR_TAREA";
export const ELIMINAR_TAREA = "ELIMINAR_TAREA";
export const TAREA_ACTUAL = "TAREA_ACTUAL";
export const ACTUALIZAR_TAREA = "ACTUALIZAR_TAREA";
export const LIMPIAR_TAREA = "LIMPIAR_TAREA";

export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";

export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO";
export const REGISTRO_ERROR = "REGISTRO_ERROR";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
