import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Alert(alert) {
  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.title,
      text: alert.text,
      type: alert.type,
    });
  };

  return sweetAlertHandler(alert);
}

export default Alert;
