import React from "react";

// const SignIn1 = React.lazy(() =>
//   import("./Demo/Authentication/SignIn/SignIn1")
// );
const LogOut = React.lazy(() => import("./App/components/Auth/LogOut"));

const route = [
  // { path: "/auth/signin-1", exact: true, name: "Signin 1", component: SignIn1 },
  { path: "/Auth/LogOut", exact: true, name: "Login", component: LogOut },
];

export default route;
