// import React, { Fragment, useState } from "react";

// const Login = () => {
//   const [user, saveUser] = useState({
//     email: "",
//     password: "",
//   });
//   const { email, password } = user;

//   const onChange = (e) => {
//     saveUser({
//       ...user,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const onSubmit = (e) => {
//     e.preventDefault();
//   };

//   return (
//     <Fragment>
//       <h1>Login</h1>
//       <form onSubmit={onSubmit}>
//         <input name="email" value={email} onChange={onChange}></input>
//         <input name="password" value={password} onChange={onChange}></input>
//       </form>
//     </Fragment>
//   );
// };

// export default Login;

import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import "./../../../assets/scss/style.scss";

import Aux from "../../../hoc/_Aux/index.js";
import Breadcrumb from "./../../../App/layout/AdminLayout/Breadcrumb";
import AuthContext from "../../context/auth/authContext";

import Alert from "./../../components/Alert";

function Login(props) {
  const authContext = useContext(AuthContext);
  const {
    autenticado,
    iniciarSesion,
    validarSesion,
    loading,
    validarSesionError,
  } = authContext;

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    document.title = `Welcome to Task & Flows`;
    if (autenticado) {
      props.history.push("/home");
    }
  }, [autenticado, props.history]);
  const [user, setUser] = useState({
    login: "",
    password: "",
  });
  const { login, password } = user;
  const onChangue = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    validarSesion();
    if (login.trim() === "" || password.trim() === "") {
      Alert({
        title: "Atención!",
        type: "warning",
        text: "Todos los campos son necesarios!",
      });
      validarSesionError();
      return;
    }
    iniciarSesion({ login, password });
  };

  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon" />
              </div>
              <h3 className="mb-4">Tasks & Flows v0.0.3</h3>
              <form onSubmit={onSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="login"
                    name="login"
                    className="form-control"
                    placeholder="Email"
                    value={login}
                    onChange={onChangue}
                  />
                </div>
                <div className="input-group mb-4">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="password"
                    value={password}
                    onChange={onChangue}
                  />
                </div>
                <div className="form-group text-left">
                  <div className="checkbox checkbox-fill d-inline">
                    <input
                      type="checkbox"
                      name="checkbox-fill-1"
                      id="checkbox-fill-a1"
                    />
                    <label htmlFor="checkbox-fill-a1" className="cr">
                      {" "}
                      Save credentials
                    </label>
                  </div>
                </div>
                <button
                  className="btn btn-primary shadow-2 mb-4"
                  disabled={loading}
                >
                  {loading && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  Login
                </button>
                <p className="mb-2 text-muted">
                  Forgot password?{" "}
                  <NavLink to="/auth/reset-password-1">Reset</NavLink>
                </p>
                <p className="mb-0 text-muted">
                  Don’t have an account? <NavLink to="/Home">Signup</NavLink>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
}

// class Login extends React.Component {
//   render() {
//     return (
//       <Aux>
//         <Breadcrumb />
//         <div className="auth-wrapper">
//           <div className="auth-content">
//             <div className="auth-bg">
//               <span className="r" />
//               <span className="r s" />
//               <span className="r s" />
//               <span className="r" />
//             </div>
//             <div className="card">
//               <div className="card-body text-center">
//                 <div className="mb-4">
//                   <i className="feather icon-unlock auth-icon" />
//                 </div>
//                 <h3 className="mb-4">Task Flow</h3>
//                 <div className="input-group mb-3">
//                   <input
//                     type="email"
//                     className="form-control"
//                     placeholder="Email"
//                   />
//                 </div>
//                 <div className="input-group mb-4">
//                   <input
//                     type="password"
//                     className="form-control"
//                     placeholder="password"
//                   />
//                 </div>
//                 <div className="form-group text-left">
//                   <div className="checkbox checkbox-fill d-inline">
//                     <input
//                       type="checkbox"
//                       name="checkbox-fill-1"
//                       id="checkbox-fill-a1"
//                     />
//                     <label htmlFor="checkbox-fill-a1" className="cr">
//                       {" "}
//                       Save credentials
//                     </label>
//                   </div>
//                 </div>
//                 <button className="btn btn-primary shadow-2 mb-4">Login</button>
//                 <p className="mb-2 text-muted">
//                   Forgot password?{" "}
//                   <NavLink to="/auth/reset-password-1">Reset</NavLink>
//                 </p>
//                 <p className="mb-0 text-muted">
//                   Don’t have an account? <NavLink to="Board">Signup</NavLink>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Aux>
//     );
//   }
// }

export default Login;
