export default {
  items: [
    {
      id: "pages",
      title: "Pages",
      type: "group",
      icon: "icon-pages",
      children: [
        {
          id: "home-page2",
          title: "Home",
          type: "item",
          url: "/Home",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },
        {
          id: "dashboard",
          title: "DashBoard",
          type: "item",
          url: "/DashBoard",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },
        {
          id: "agileboard",
          title: "AgileBoard",
          type: "item",
          url: "/AgileBoard",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },
        // {
        //   id: "home-page",
        //   title: "Sample Page",
        //   type: "item",
        //   url: "/home",
        //   classes: "nav-item",
        //   icon: "feather icon-sidebar",
        // },
        {
          id: "myTask",
          title: "MyTask",
          type: "item",
          url: "/Task/myTask",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },
        // {
        //   id: "signin-1",
        //   title: "Sign in",
        //   type: "item",
        //   url: "/Auth/Login",
        //   icon: "feather icon-lock",
        //   badge: {
        //     title: "New",
        //     type: "label-danger",
        //   },
        //   target: true,
        //   breadcrumbs: false,
        // },
        {
          id: "menu-level",
          title: "Menu Levels",
          type: "collapse",
          icon: "feather icon-menu",
          children: [
            {
              id: "menu-level-1.1",
              title: "Menu Level 1.1",
              type: "item",
              url: "#!",
            },
            {
              id: "menu-level-1.2",
              title: "Menu Level 2.2",
              type: "collapse",
              children: [
                {
                  id: "menu-level-2.1",
                  title: "Menu Level 2.1",
                  type: "item",
                  url: "#",
                },
                {
                  id: "menu-level-2.2",
                  title: "Menu Level 2.2",
                  type: "collapse",
                  children: [
                    {
                      id: "menu-level-3.1",
                      title: "Menu Level 3.1",
                      type: "item",
                      url: "#",
                    },
                    {
                      id: "menu-level-3.2",
                      title: "Menu Level 3.2",
                      type: "item",
                      url: "#",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
